<template>
  <div class="my-second info bgcolor">
      <Head :title="$t('info.add_address')" :show="true" :right="true"  >
      </Head>
      <div class="common-box info-box">
            <div class="address-add">
                <van-form class="address-form" @submit="addAddr" >
                        <van-cell-group class="form-box">
                             <van-field
                                v-model="obj.realname"
                                type="text"
                                name="realname"
                                :label="$t('info.get_p')"
                                :placeholder="$t('info.input_get_p')"
                                class="cell-first"
                                :rules="[{required:true,message: $t('info.p_input_get_p')}]"
                            />
                            <van-field
                                v-model="obj.area"
                                type="text"
                                name="area"
                                :label="$t('info.c_area')"
                                :rules="[]"
                                right-icon="arrow"
                                readonly
                                class="van-nohidden van-countrynum"
                                @click.stop="showSome"
                                
                            >
                                <template #input>
                                    <div class="tel-qu width100">
                                        <AreaNum ref="choosenum" @excountry="getCountryItem"  :country-code="obj.zipcode"/>
                                        <span class="my-country">{{obj.country}}</span>
                                    </div>
                                </template>
                            </van-field>
                            <van-field
                                v-model="obj.mobile"
                                type="tel"
                                name="mobile"
                                :label="$t('info.phone_num')"
                                :placeholder="$t('info.input_phone_num')"
                                
                                :rules="[{required:true,message:$t('info.p_input_phone_num')}]"
                            />
                            <van-field
                                v-model="obj.city"
                                type="text"
                                name="city"
                                :label="$t('info.area')"
                                :placeholder="$t('info.input_area')"
                                :rules="[{required:true,message:$t('info.p_input_area')}]"
                            />
                            <van-field
                                v-model="obj.address"
                                type="text"
                                name="address"
                                :label="$t('info.adderss')"
                                :placeholder="$t('info.adderss_info')"
                                :rules="[{required:true,message: $t('info.p_adderss')}]"
                                class="cell-last"
                            />
                        </van-cell-group>
                        <div class="more-box">
                            <p class="more-box-right">{{$t('info.reset_address')}}</p>
                            <van-switch v-model="obj.isdefault" size="18" class="more-box-switch" />
                        </div>
                        <van-button class="add-btn" native-type="submit">{{$t('info.keep')}}</van-button>
                </van-form>
            </div>
      </div>
  </div>
</template>

<script>
import {areaCode,myinfo} from "@/common"
import {Notify} from "vant"
export default {
    mixins:[myinfo],
    data(){
        return {
            obj:{
                area:"66",
                country:"China (中国)",
                zipcode:"th",
                ...areaCode
            }
        }
    },
    methods:{
        getCountryItem(data){
            this.obj.area = data.dialCode;
            this.obj.country = data.name;
            this.obj.zipcode = data.code;
        },
        showSome(){
            this.$refs.choosenum.hideSubMenu = false;   // 显示国旗 
        },
        addAddr(value){
            this.$ajax.addnewaddr({
                ...value,
                isdefault:this.obj.isdefault?1:0,
                country:this.obj.country,
                zipcode:this.obj.zipcode
            }).then(res=>{
                if(res.code==200){
                    this.$router.go(-1);
                    Notify({ type: 'success', message: res.msg });
                    this.setdashboard({...this.dashboard,is_addr:1});  // 已经有银行卡
                }
            })
        }
    }
};
</script>


